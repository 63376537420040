import { graphql, useStaticQuery } from 'gatsby'

import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import React from 'react'

const ContactComplete = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="contact" lang="ja" />
        <title>{`お問い合わせ | ${title}`}</title>
      </Helmet>
      <div className="common-header clearfix">
        <h1>お問い合わせ完了</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>お問い合わせ</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <p>
              お問い合わせいただきありがとうございました。
              <br />
              お問い合わせを受け付けました。
              <br />
              <br />
              折り返し、担当者よりご連絡いたしますので、恐れ入りますが、しばらくお待ちください。
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactComplete
